<template>
    <v-container fluid class="px-6 py-6">
        <v-row class="mb-12">
            <v-col md="12">
                <datatable-full
                    :data="domainsData"
                    :tableSettings="tableSettings"
                    :isDataLoading="isDomainsDataLoading"
                    :dataError="domainsDataError"
                    :header="$t('domains.table.header')"
                    :subheader="$t('domains.table.subheader')"
                    @addItem="addDomain"
                    @editItem="editDomain"
                    @deleteItem="deleteDomain"
                >
                    <template #last24h_status="{ item }">
                        {{ item.last24h_status }}%
                    </template>
                </datatable-full>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DatatableFull from '@/components/Tables/DatatableFull.vue';

import { getDomain, addDomain, deleteDomain, editDomain } from '@/api';

export default {
    name: 'Domains',
    components: {
        DatatableFull,
    },
    data() {
        return {
            domainsData: null,
            isDomainsDataLoading: true,
            domainsDataError: false,
            tableSettings: {
                headers: [
                    {
                        text: this.$t('domains.table.tableHeader.name'),
                        align: 'start',
                        cellClass: 'border-bottom',
                        value: 'name',
                        class: 'text-secondary font-weight-bolder opacity-7 border-bottom ps-6',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.ip'),
                        value: 'ip',
                        class: 'text-secondary font-weight-bolder opacity-7',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.status'),
                        value: 'current_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        width: '90px',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.last24h'),
                        value: 'last24h_status',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '130px',
                    },
                    {
                        text: this.$t('domains.table.tableHeader.actions'),
                        value: 'actions',
                        class: 'text-secondary font-weight-bolder opacity-7',
                        sortable: false,
                        width: '120px',
                    },
                ],
                editedItem: {
                    name: '',
                    ip: '',
                },
                editedItemFields: {
                    name: {
                        name: `${this.$t('domains.table.tableHeader.name')} *`,
                    },
                    ip: {
                        name: `${this.$t('domains.table.tableHeader.ip')} *`,
                    },
                },
            },
        };
    },
    methods: {
        getDomain: async function () {
            try {
                this.isDomainsDataLoading = true;
                this.domainsData = await getDomain();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Loaded domains');
                }
            } catch (error) {
                this.domainsDataError = true;
                console.error(error);
            } finally {
                this.isDomainsDataLoading = false;
            }
        },
        addDomain: async function (data) {
            try {
                await addDomain(data);
                this.getDomain();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Added domain', data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        editDomain: async function (data) {
            try {
                await editDomain(data);
                this.getDomain();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Edited domain', data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        deleteDomain: async function (id) {
            try {
                await deleteDomain(id);
                this.getDomain();

                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.VUE_APP_API_DEBUG === 'true'
                ) {
                    console.log('API Action: Deleted domain with id: ', id);
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted: async function () {
        await this.getDomain();
    },
};
</script>
